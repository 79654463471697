import logo from './logo.svg';
import gif from './images/adventure-time-gunter.gif'
import './App.css';
import Nav from './components/NavBar';
import Header from './components/Hero';
import About from './components/About';
import Projects from "./components/Projects";
import Contact from './components/Contact';

function App() {
  return (
    <div className="App">
      <Nav color ="cyan" />
      <Header color="cyan" />
      <About color="cyan" />
      <Projects color="cyan" />
      <Contact color="cyan" />
    </div>
  );
}

export default App;